import * as React from "react";
import { ReactNode } from "react";
import "./ckContentStyles.css";

export interface IRichTextView extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const RichTextView = ({ children, className, ...props }: IRichTextView) => {
  return (
    <div className={`ck-content ${className}`} {...props}>
      {children}
    </div>
  );
};

export default RichTextView;
